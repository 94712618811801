body {
    margin: 0;
    overflow-x: hidden;
    max-width: 100%;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #666;
  z-index: 1;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 5px;
}
